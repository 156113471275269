<template>
  <div class="app" :class="{ 'cabinet': isLogin.value }">
    <Header :isLogin="isLogin.value" />
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
    <main class="h-dvh">
      <RouterView />
    </main>
  </div>

  <Dialog :visible="visible.value" modal :style="{ width: '20rem' }">
    <div class="flex justify-center">
      <img src="img/icons/short-logo.jpg" class="w-2/4" alt="">
    </div>
    <div class="flex justify-center text-center">
      <div class="p-text-secondary block mb-5">
        Для вашего удобства мы предлагаем установить мобильное приложение BOKON!
      </div>
    </div>
    <div class="flex justify-center gap-2">
      <Button type="button" label="Скачать приложение" severity="info" @click="downloadApp"></Button>
    </div>
  </Dialog>
</template>
<style>
.p-dialog-header {
  padding: 5px;
}

.p-dialog-header-icons {
  display: none !important;
}

</style>
<script setup>
import { onMounted, provide, ref, watch, reactive } from "vue";
import Header from "./components/Header.vue";
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';

const login = async ()  => {
  localStorage.setItem('isLogin', 1);
  await checkLogin();
}

const exit = async ()  => {
  localStorage.setItem('isLogin', 0);
  await checkLogin();
}

const checkLogin = async ()  => {
  const checkLogin = localStorage.getItem('isLogin');
  isLogin.value = (checkLogin == 1) ? true : false;
}


// const isLogin = reactive({ value: false })
const isLogin = reactive({ value: true });
const visible = reactive({ value: false });
const deferredPrompt = reactive({ value: null });

const checkInstallApp = () => {
  window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault();
    visible.value = true;
    deferredPrompt.value = e;
  });
}

const downloadApp = async () => {
  if(!deferredPrompt.value) return;

  deferredPrompt.value.prompt();

  // const { outcome } = await deferredPrompt.value.userChoice;
  // // The deferredPrompt can only be used once.
  // deferredPrompt.value = null;
  // // Act on the user's choice
  // if (outcome === 'accepted') {
  //   console.log('User accepted the install prompt.');
  // } else if (outcome === 'dismissed') {
  //   console.log('User dismissed the install prompt');
  // }
}

onMounted(async () =>  {
  // await checkLogin();
  checkInstallApp();
});

provide('login', login);
provide('exit', exit);
</script>