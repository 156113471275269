import './assets/main.css';
import 'primevue/resources/themes/aura-light-green/theme.css'
import 'primeicons/primeicons.css'

import { createApp } from "vue";
import { createWebHistory, createRouter } from 'vue-router'

import App from "./App.vue";
import PrimeVue from 'primevue/config';
import "./registerServiceWorker";

import routes from '@/routes';
// import store from '@/store';

const router = createRouter({
  mode: "history",
  routes,
  history: createWebHistory()
});

createApp(App)
  .use(router)
  // .use(store)
  .use(PrimeVue)
  .mount("#app");
