<template>
  <div class="container">
    <div class="mt-14 mb-8 main-logo"></div>
    <div class="mx-4">
      <div class="mb-8 flex justify-between items-end">
        <div><img src="../../assets/logo.png" alt="" style="max-width: 160px;"></div>
        <div class="mb-8">
          <div class="flex bg-white w-32 h-10 rounded-lg items-center justify-between p-2.5 pr-1" style="border: 1px solid rgba(222, 222, 226, 1);">
            <div class="before">
              <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="20" height="20" transform="translate(0.728516 0.648438)" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7279 17.3151C14.4098 17.3151 17.3945 14.3303 17.3945 10.6484C17.3945 6.9665 14.4098 3.98173 10.7279 3.98173C7.04597 3.98173 4.0612 6.9665 4.0612 10.6484C4.0612 14.3303 7.04597 17.3151 10.7279 17.3151ZM10.7279 18.9817C15.3302 18.9817 19.0612 15.2508 19.0612 10.6484C19.0612 6.04602 15.3302 2.31506 10.7279 2.31506C6.12549 2.31506 2.39453 6.04602 2.39453 10.6484C2.39453 15.2508 6.12549 18.9817 10.7279 18.9817Z" fill="#FF9431"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.1847 15.7956C12.8003 14.5644 13.2292 12.7469 13.2292 10.6484C13.2292 8.54985 12.8003 6.73237 12.1847 5.5012C11.5193 4.17033 10.9084 3.98173 10.7292 3.98173C10.55 3.98173 9.93903 4.17033 9.2736 5.5012C8.65801 6.73237 8.22917 8.54985 8.22917 10.6484C8.22917 12.7469 8.65801 14.5644 9.2736 15.7956C9.93903 17.1265 10.55 17.3151 10.7292 17.3151C10.9084 17.3151 11.5193 17.1265 12.1847 15.7956ZM10.7292 18.9817C13.0304 18.9817 14.8958 15.2508 14.8958 10.6484C14.8958 6.04602 13.0304 2.31506 10.7292 2.31506C8.42798 2.31506 6.5625 6.04602 6.5625 10.6484C6.5625 15.2508 8.42798 18.9817 10.7292 18.9817Z" fill="#FF9431"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.0201 11.4817C19.0473 11.2076 19.0612 10.9296 19.0612 10.6484C19.0612 10.3671 19.0473 10.0892 19.0201 9.81506H2.43568C2.40846 10.0892 2.39453 10.3671 2.39453 10.6484C2.39453 10.9296 2.40846 11.2076 2.43568 11.4817H19.0201Z" fill="#FF9431"/>
              </svg>
            </div>
            <span style="font-size: 14px;font-weight: 500;line-height: 20px;color: rgba(122, 118, 128, 1);">
              Русский
            </span>
            <div class="after">
              <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="20.0747" height="20.0672" transform="translate(0.974609 0.269897)" fill="white"/>
                <path d="M7.66602 8.63123L11.0118 11.9758L14.3576 8.63123" stroke="#FF9431" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>