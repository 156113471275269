export default [
  // {
  //   path: '/',
  //   name: 'Login',
  //   component: () => import('@/components/pages/user/Login.vue'),
  //   meta: {
  //     authRequired: false
  //   }
  // },
  // {
  //   path: '/check-code',
  //   name: 'CheckCode',
  //   component: () => import('@/components/pages/user/CheckCode.vue'),
  //   meta: {
  //     authRequired: false
  //   }
  // },
  // {
  //   path: '/edit-profile',
  //   name: 'EditProfile',
  //   component: () => import('@/components/pages/user/EditProfile.vue'),
  //   meta: {
  //     authRequired: true
  //   }
  // },
  {
    path: '/',
    name: 'Catalog',
    component: () => import('@/components/pages/catalog/Index.vue'),
    meta: {
      authRequired: true
    }
  },
  // {
  //   path: '/catalog',
  //   name: 'Catalog',
  //   component: () => import('@/components/pages/catalog/Index.vue'),
  //   meta: {
  //     authRequired: true
  //   }
  // },
  {
    path: '/catalog/category/:parentId',
    name: 'ListCategories',
    component: () => import('@/components/pages/catalog/SingleCategory.vue'),
    meta: {
      layout: 'main',
      authRequired: true
    }
  },
  {
    path: '/catalog/item/:id',
    name: 'SigleItem',
    component: () => import('@/components/pages/catalog/SigleItem.vue'),
    meta: {
      layout: 'main',
      authRequired: true
    }
  },
  {
    path: '/news',
    name: 'NewsList',
    component: () => import('@/components/pages/news/NewsList.vue'),
    meta: {
      layout: 'main',
      authRequired: true
    }
  },
  {
    path: '/news/item/:id',
    name: 'NewsItem',
    component: () => import('@/components/pages/news/SingleItem.vue'),
    meta: {
      layout: 'main',
      authRequired: true
    }
  },
  // {
  //   path: '/clients/:client/edit',
  //   name: 'client-edit',
  //   component: () => import('@/pages/clients/ClientItem.vue'),
  //   meta: {
  //     layout: 'main',
  //     authRequired: true
  //   }
  // },
]