<template>
  <nav-profile v-if="isLogin"></nav-profile>
  <nav-login v-else></nav-login>
</template>
<script setup>
import { onMounted, provide, ref, watch } from "vue";

import NavProfile from "./widgets/NavProfile.vue";
import NavLogin from "./widgets/NavLogin.vue";

const props = defineProps({
  isLogin: Boolean,
});

onMounted(() =>  {});

// export default {
//   name: "Header",
//   props: {
//     isLogin: Boolean,
//   },
//   components: {
//     NavProfile,
//     NavLogin
//   },
//   mounted() {
//     console.log(this.isLogin);
//   },
// };
</script>