<template>
  <Sidebar v-model:visible="visible">
    <template #container="{ closeCallback }">
      <div class="flex flex-col h-full">
        <div
          class="flex align-items-center justify-content-between px-4 py-4 flex-shrink-0 menu"
        >
          <span class="inline-flex align-items-center gap-2">
            <span class="font-medium text-xl text-primary">МЕНЮ</span>
          </span>
        </div>
        <div class="overflow-y-auto">
          <ul class="list-none px-4 m-0">
            <li>
              <ul class="list-none p-0 m-0 overflow-hidden navigate-menu">
                <li>
                  <a
                    v-ripple
                    class="flex align-items-center cursor-pointer py-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
                    href="/"
                  >
                    <span class="menu-item ">Справочник</span>
                  </a>
                </li>
                <li>
                  <a
                    v-ripple
                    class="flex align-items-center cursor-pointer py-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
                    href="/news"
                  >
                    <span class="menu-item ">Новости</span>
                  </a>
                </li>
                <li>
                  <a
                    v-ripple
                    class="flex align-items-center cursor-pointer py-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
                  >
                    <span class="menu-item">Помощь</span>
                  </a>
                </li>
                <!-- <li>
                  <a
                    v-ripple
                    class="flex align-items-center cursor-pointer py-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
                  >
                    <span class="menu-item">Видео</span>
                  </a>
                </li> -->
                <li>
                  <a
                    v-ripple
                    class="flex align-items-center cursor-pointer py-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
                  >
                    <span class="menu-item">Поделиться приложением</span>
                  </a>
                </li>
                <!-- <li>
                  <a
                    v-ripple
                    class="flex align-items-center cursor-pointer py-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
                  >
                    <span class="menu-item">Оплата</span>
                  </a>
                </li> -->
                <li>
                  <a
                    v-ripple
                    class="flex align-items-center cursor-pointer py-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
                  >
                    <span class="menu-item">Сообщить об ошибке</span>
                  </a>
                </li>
                <!-- <li>
                  <a
                    v-ripple
                    class="flex align-items-center cursor-pointer py-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
                    @click="onSubmit(); closeCallback();"
                  >
                    <span class="menu-item">Выход</span>
                  </a>
                </li> -->
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </template>
  </Sidebar>
  <header class="container mx-4 pt-8 flex">
    <Button
      v-if="needBack"
      @click="backPage()"
      class="size-11 bg-white flex justify-center items-center mr-2.5"
    >
      <div class="icons-wrap">
        <i class="pi pi-arrow-left" style="color: #FF9431;"></i>
      </div>
    </Button>
    <Button
      @click="visible = true"
      class="size-11 bg-white flex justify-center items-center"
    >
      <div class="icons-wrap">
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </div>
    </Button>
    <!-- <Breadcrumb
      :home="home"
      :model="items"
      :class="{ invisible: !showBreadcrumb }"
    /> -->
  </header>
</template>
<style>
.menu {
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.12);
}
.menu-item {
  @apply text-base font-normal;
  letter-spacing: .15px;
}

.navigate-menu li {
  border-bottom: 1px solid #bfbfbf;
}
</style>

<script setup>
import { inject } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import Sidebar from "primevue/sidebar";
import Button from "primevue/button";
import Breadcrumb from "primevue/breadcrumb";

const router = useRouter()
const route = useRoute()

const exit = inject('exit');
const onSubmit = () => {
  router.push('/')
  exit();
}


const backPage = () => {
  router.go(-1)
}

</script>

<script>

export default {
  data: () => ({
    visible: false,
    status: "personal",
    showBreadcrumb: false,
    items: [
      {
        url: "/",
        label: "test",
      },
    ],
    needBack: false
  }),
  methods: {
    getHistory()  {
      this.needBack = false;
      if(window.history.state.current != '/') this.needBack = true;
    },
  },
  watch: {
    $route() {
      this.getHistory();
    },
  },
};
</script>